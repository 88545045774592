'use strict';

const isMobile = require('../util/isMobile');

const ProductTile = {};

/**
 * Initializes the js for product tiles
 */
ProductTile.init = () => {
    if (isMobile.any()) return;

    ProductTile.onProductTileHover();
    ProductTile.onProductImageHover();
    ProductTile.onProductImageLeaveHover();
    ProductTile.onSwatchImageHover();
    ProductTile.onSwatchImageLeaveHover();
};

/**
 * Changes the product tile image to a new one
 * @param {JQuery} $productTileImage image element of product tile
 * @param {string} srcset srcset for new image
 */
ProductTile.changeDisplayedImageOnDesktop = ($productTileImage, srcset) => {
    const $sourceDesktop = $productTileImage.find('source[data-media="desktop"]');
    $sourceDesktop.prop('srcset', srcset);
};

/**
 * Shows the correct price on the product tile for the corresponding swatch sku
 * @param {JQuery} $productTile the product tile element
 * @param {string} swatchSku the swatch sku needed to show the corresponding price
 */
ProductTile.showPriceForSwatch = ($productTile, swatchSku) => {
    $productTile.find('.js-plp-tile-og-price').addClass('d-none');
    $productTile.find('.js-plp-tile-hover-price').addClass('d-none');
    $productTile.find(`.js-plp-tile-hover-price[data-swatch-sku="${swatchSku}"]`).removeClass('d-none');
};

/**
 * Shows the original price of the product tile
 * @param {JQuery} $productTile the product tile element
 */
ProductTile.showOriginalPrice = ($productTile) => {
    $productTile.find('.js-plp-tile-hover-price').addClass('d-none');
    $productTile.find('.js-plp-tile-og-price').removeClass('d-none');
};

/**
 * Shows the labels on the product tile for the corresponding swatch sku
 * @param {JQuery} $productTile the product tile element
 * @param {string} swatchSku the swatch sku needed to show the corresponding labels
 */
ProductTile.showDiscountLabelsForSwatch = ($productTile, swatchSku) => {
    const newLabelsHtml = $productTile.find(`.js-plp-tile-hover-discount-labels[data-swatch-sku=${swatchSku}] .discount-label-plp-wrapper`).html();
    $productTile.find('.product-tile__image .discount-label-plp-wrapper').html(newLabelsHtml);
};

/**
 * Shows the original discount labels of the product tile
 * @param {JQuery} $productTile the product tile element
 */
ProductTile.showOriginalDiscountLabels = ($productTile) => {
    const originalDiscountLabelsHtml = $productTile.find('.js-plp-tile-original-discount-labels-holder .discount-label-plp-wrapper').html();
    $productTile.find('.product-tile__image .discount-label-plp-wrapper').html(originalDiscountLabelsHtml);
};

/**
 * Loads the html which needs to be shown when hovering on a product tile and shows it
 */
ProductTile.onProductTileHover = () => {
    $('body').on('mouseenter', '.product-tile', (e) => {
        const $this = $(e.currentTarget);
        const url = $this.data('get-product-tile-hover-content-url');

        if ($this.hasClass('hover-content-loaded') || !url) return;

        $.get(url, (data) => {
            $this.find('.product-tile__hover-content-wrapper').html(data);
            $this.addClass('hover-content-loaded');
        });
    });
};

/**
 * Shows the 2nd product image on product tile image hover if it exists
*/
ProductTile.onProductImageHover = () => {
    $('body').on('mouseenter', '.product-tile .product-tile__image', (event) => {
        const $productTileImage = $(event.currentTarget);
        const $productTileHoverImageHolder = $productTileImage.find('.js-product-tile-hover-image-holder');

        if (!$productTileHoverImageHolder.length) return;

        ProductTile.changeDisplayedImageOnDesktop(
            $productTileImage,
            $productTileHoverImageHolder.data('hover-image-srcset')
        );
    });
};

/**
 * Resets to the original product image on product image hover leave
 */
ProductTile.onProductImageLeaveHover = () => {
    $('body').on('mouseleave', '.product-tile .product-tile__image', (event) => {
        const $productTileImage = $(event.currentTarget);
        const $productTileHoverImageHolder = $productTileImage.find('.js-product-tile-hover-image-holder');

        if (!$productTileHoverImageHolder.length) return;

        ProductTile.changeDisplayedImageOnDesktop(
            $productTileImage,
            $productTileHoverImageHolder.data('original-image-srcset')
        );
    });
};

/**
 * Shows the swatch image in the product tile image and updates the price on swatch hover
 */
ProductTile.onSwatchImageHover = () => {
    $('body').on('mouseenter', '.color-swatches .js-swatch-circle', (event) => {
        const $this = $(event.currentTarget);
        const $productTile = $this.parents('.product-tile');
        const $productTileImage = $productTile.find('.product-tile__image');
        const swatchSku = $this.data('swatch-sku');
        ProductTile.changeDisplayedImageOnDesktop(
            $productTileImage,
            $this.data('hover-image-srcset')
        );
        ProductTile.showPriceForSwatch($productTile, swatchSku);
        ProductTile.showDiscountLabelsForSwatch($productTile, swatchSku);
    });
};

/**
 * Resets the product image and price to the original on the product tile on swatch hover leave
 */
ProductTile.onSwatchImageLeaveHover = () => {
    $('body').on('mouseleave', '.color-swatches .js-swatch-circle', (event) => {
        const $this = $(event.currentTarget);
        const $productTile = $this.parents('.product-tile');
        const $productTileImage = $productTile.find('.product-tile__image');
        ProductTile.changeDisplayedImageOnDesktop(
            $productTileImage,
            $this.data('original-image-srcset')
        );
        ProductTile.showOriginalPrice($productTile);
        ProductTile.showOriginalDiscountLabels($productTile);
    });
};

module.exports = ProductTile;
